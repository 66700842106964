import { everyActionsPermissionIsEnabled } from '.'

export const CHARGE_ACTIONS_ENUM = {
  view: 'charge:view',
  search: 'charge:search',
  get: 'charge:get'
}

const CHARGE_ACTIONS = Object.values(CHARGE_ACTIONS_ENUM)

const ALLOWS_MERCHANTS_EXPORT_REL = [
  'merch_8j5KnndFRRhoMKoP', // Whirlpool,
  'merch_wm4elOiQMIJjx1v0',
  'merch_wBXvQoOVH4IrQWVq',
  'merch_17gx3AGC0unRZ8Oa',
  'merch_Pqz3bM4Tai5VDnrk' // Staging
]

const chargeEnabled = permissionIsEnabledFn => {
  return everyActionsPermissionIsEnabled(CHARGE_ACTIONS, permissionIsEnabledFn)
}

const chargeViewEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(CHARGE_ACTIONS_ENUM.view)
}

const chargeSearchEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(CHARGE_ACTIONS_ENUM.search)
}

const chargeGetEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(CHARGE_ACTIONS_ENUM.get)
}
const chargesReportEnabled = merchantId => {
  return ALLOWS_MERCHANTS_EXPORT_REL.includes(merchantId)
}

export {
  chargeViewEnabled,
  chargeSearchEnabled,
  chargeGetEnabled,
  chargesReportEnabled
}

export default chargeEnabled
